<template>
    <div>
        <div>
            <div style="float:right">
                <el-button size="mini" @click="getData()">刷新</el-button>
            </div>
        <el-page-header @back="$router.go(-1)" content="排班表"/>
        </div>
        <div style="margin-top: 20px">
            <div style="text-align: center;background-color: #eee;padding:10px;">
                <el-date-picker
                    v-model="date"
                    type="week"
                    format="yyyy 第 WW 周"
                    value-format="yyyy-MM-dd"
                    placeholder="选择周"
                    style="text-align: center"
                    @change="handlePicker">
                </el-date-picker>
            </div>
            <div style="display: flex; flex-direction: row;border:0px solid #eee;padding:10px;">
                <div style="width: 20%"></div>
                <div style="flex:1" v-for="(a,idx) in worksheet.Area" :key="idx+1"><b>{{a.name}}</b></div>
            </div>
            <div v-for="(w, i) in worksheet.Resp" :key="i" :class="i%2==0?'bg-eee':'bg-fff'">
                <div style="display: flex; flex-direction: row;border-top:1px solid #eee;padding:10px;">
                    <div style="width: 20%;font-weight: bold;text-align: center;">
                        {{wds[w.Weekday]}}
                        <div style="font-size: 50%">{{w.Date}}</div>
                    </div>
                    <div v-for="(a, j) in w.Area" :key="j" style="flex:1">
                        <div style="border-bottom: 1px solid #eee;min-height:18px" v-show="a.Morning">
                            <span style="color:#ccc;font-size: 80%" v-show="a.Morning"> 上午: </span>
                            <router-link :to="`/main/appoint?date=${w.Date}&doctor=${k}`" :class="v.Class" v-for="(v, k, h) in a.Morning" :key="h">
                                {{k}}<small>({{v.Used}}/{{v.Count}})</small>
                            </router-link>
                        </div>
                        <div v-show="a.Afternoon">
                            <span style="color:#ccc;font-size: 80%" v-show="a.Afternoon"> 下午: </span>
                            <router-link :to="`/main/appoint?date=${w.Date}&doctor=${k}`" :class="v.Class" v-for="(v, k, h) in a.Afternoon" :key="h">
                                {{k}}<small>({{v.Used}}/{{v.Count}})</small>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            let now = new Date();
            let nowTime = now.getTime()
            let day = now.getDay() || 7
            let monday = nowTime - (day - 1) * 24 * 60 * 60 * 1000
            let strDate = new Date(monday).format("yyyy-MM-dd")
            return {
                date: strDate,
                wds: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                area: [],
                worksheet: []
            }
        },
        methods: {
            handlePicker(e) {
                console.log(e)
                this.getData()
            },
            async getData() {
                const resp = await this.$http.get(`/surgery/getWeekSheet?date=${this.date}`)
                console.log(resp.data.data)
                this.area = resp.data.data.area
                this.worksheet = resp.data.data
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    a {
        text-decoration: none;
        color: #333333;
    }
    .bg-fff {
        background-color: #fff;
    }

    .bg-eee {
        background-color: #f7f7f7;
    }
    .worksheet, .overtime, .special {
        white-space: nowrap;
    }
    .overtime {
        color: green;
    }
    .special {
        color: red;
    }
    /deep/.el-input--prefix .el-input__inner {
        padding-left: 30px;
        text-align: center;
    }
</style>
